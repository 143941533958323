export function openNav() {
  $("#main-navigation").removeClass("hidden");
  $("#hamburger .close").removeClass("hidden");
  $("#hamburger .open").addClass("hidden");
}

export function closeNav() {
  $("#main-navigation").addClass("hidden");
  $("#hamburger .open").removeClass("hidden");
  $("#hamburger .close").addClass("hidden");
}
