import { openNav, closeNav } from "./modules/navigation.js";

// Stop transitions before page has loaded
$(window).on("load", function () {
  $("body").removeClass("preload");
});

// Toggle navigation
$("#hamburger").click(function (e) {
  e.preventDefault();
  $($("#main-navigation").hasClass("hidden") ? openNav() : closeNav());
});

// Reset if browser is resized
$(window).resize(function () {
  if ($(window).width() >= 960) {
    closeNav();
  }
});

// Video player
$("#playButton").click(function () {
  var vidSrc = $(this).data("src");
  $(this)
    .parent()
    .parent()
    .append(
      '<iframe src="' +
        vidSrc +
        '" class="absolute w-full h-full" frameborder="0" allowfullscreen></iframe>'
    );
});
